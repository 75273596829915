import React, { useMemo, Suspense, lazy } from 'react';
import { observer } from 'mobx-react-lite';
import { useApplication } from './Contexts/ApplicationContext';
import { useNFT } from './Contexts/SettingsContext';
import { Page } from '../Services/controllers/StepController';
import { PageLayout } from './Components/PageLayout';
const ThreeDSPaymentPage = lazy(() => import('./Pages/PaymentPage/ThreeDSPaymentPage'));
const PendingTransactionPage = lazy(() => import('./Pages/PaymentPage/TransactionPage/PendingTransactionPage'));
const ErrorTransactionPage = lazy(() => import('./Pages/PaymentPage/TransactionPage/ErrorTransactionPage'));
const AddressPageManager = lazy(() => import('./Pages/AddressPage'));
const KYCPageManager = lazy(() => import('./Pages/KYC'));
const SimpleKYCPage = lazy(() => import('./Pages/KYC/SimpleKYCPage'));
const CalculationPage = lazy(() => import('./Pages/CalculationPage'));
const PhonePage = lazy(() => import('./Pages/PhonePage'));
const OtpPhonePage = lazy(() => import('./Pages/PhonePage/OtpPhonePage'));
const EmailPage = lazy(() => import('./Pages/EmailPage'));
const OtpEmailPage = lazy(() => import('./Pages/EmailPage/OtpEmailPage'));
const NFTPage = lazy(() => import('./Pages/NFTPage'));
const CheckOrderManager = lazy(() => import('./Pages/CheckOrder'));
const PaymentPage = lazy(() => import('./Pages/PaymentPage'));
const BlockedPage = lazy(() => import('./Pages/BlockedPage'));
const OrderDetailsPage = lazy(() => import('./Pages/OrderDetailsPage'));
const PreconditionFailedPage = lazy(() => import('./Pages/PreconditionFailedPage'));
const ErrorPage = lazy(() => import('./Pages/ErrorPage/ErrorPage'));
const PaymentWirePending = lazy(() => import('./Pages/PaymentWirePending'));
export const PageManager = observer(() => {
    const { stepController } = useApplication();
    const step = stepController.currentStep;
    const smartContractData = useNFT();
    const PageResult = useMemo(() => {
        switch (step.step) {
            case Page.MAIN:
                return smartContractData ? (<NFTPage {...smartContractData}/>) : (<CalculationPage />);
            case Page.PHONE:
                return <PhonePage />;
            case Page.PHONE_OTP:
                return <OtpPhonePage />;
            case Page.EMAIL_ADDRESS:
                return <EmailPage />;
            case Page.EMAIL_ADDRESS_OTP:
                return <OtpEmailPage />;
            case Page.TARGET_ADDRESS:
                return <AddressPageManager />;
            case Page.KYC:
                return <KYCPageManager />;
            case Page.KYC_SIMPLE:
                return <SimpleKYCPage />;
            case Page.CHECK_ORDER:
                return <CheckOrderManager />;
            case Page.PAYMENT:
                return <PaymentPage />;
            case Page.BLOCKED:
                return <BlockedPage />;
            case Page.PAYMENT_PENDING:
                return <PendingTransactionPage />;
            case Page.PAYMENT_FAILED:
                return (<ErrorTransactionPage errorCode={'paymentFailureClass' in step ? step.paymentFailureClass : ''}/>);
            case Page.SUCCESS:
            case Page.ORDER_PLACED:
                return <OrderDetailsPage />;
            case Page.FAILED:
                if ('failureType' in step &&
                    step.failureType === 'WITHDRAWAL_PRECONDITION') {
                    return (<PreconditionFailedPage failureMessage={step.failureMessage}/>);
                }
                return <OrderDetailsPage />;
            case Page.PAYMENT_3DS_REQUIRED:
                return <ThreeDSPaymentPage />;
            case Page.PAYMENT_WIRE_PENDING:
                return <PaymentWirePending />;
            default:
                return <ErrorPage />;
        }
    }, [smartContractData, step]);
    return (<Suspense fallback={<PageLayout loading hideBackArrow hideHeaderChip/>}>
      {PageResult}
    </Suspense>);
});
