import React from 'react';
import ReactDOM from 'react-dom';
import styled, { useTheme } from 'styled-components';
const OutsideContainer = styled.div `
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.background};
  z-index: 1000;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 24px;

  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;

  &.show {
    visibility: visible;
    opacity: 1;
  }
`;
const InsideContainer = styled.div `
  height: 100%;
  transition: all 0.4s ease;

  &.bottom {
    transform: translateY(600px);
  }

  &.top {
    transform: translateY(-600px);
  }

  &.left {
    transform: translateX(-100px);
  }

  &.right {
    transform: translateX(100px);
  }

  &.show {
    transform: translateX(0) translateY(0) !important;
  }
`;
const Header = styled.div `
  padding: calc(${(props) => props.theme.base} * 8);
  display: flex;
  justify-content: space-between;
`;
const StyledSvg = styled.svg `
  cursor: pointer;
  transition: all 500ms;

  &:hover {
    transform: scale3d(1.3, 1.3, 1);
  }
`;
const Close = ({ onClick, dataTestId, }) => {
    const theme = useTheme();
    return (<StyledSvg data-test-id={dataTestId} onClick={onClick} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.3334 8.54699L23.4534 6.66699L16.0001 14.1203L8.54675 6.66699L6.66675 8.54699L14.1201 16.0003L6.66675 23.4537L8.54675 25.3337L16.0001 17.8803L23.4534 25.3337L25.3334 23.4537L17.8801 16.0003L25.3334 8.54699Z" fill={theme.colors.textBodyMain}/>
    </StyledSvg>);
};
const Label = styled.div `
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.textBodyMain};
`;
const BodyWrapper = styled.div `
  height: calc(100% - 96px);
`;
export const Modal = ({ isOpen, children, label, onClose, style, anchorOrigin = 'bottom', className, dataTestIdPrefix, }) => {
    if (!document.getElementById('modal-container-element')) {
        return null;
    }
    return ReactDOM.createPortal(<OutsideContainer className={`${isOpen ? 'show' : ''}`} data-test-id={dataTestIdPrefix ? `${dataTestIdPrefix}-modal` : 'modal'}>
      <InsideContainer style={style} className={`${isOpen ? 'show' : ''} ${anchorOrigin}`}>
        <Header>
          {label ? <Label data-test-id="header-modal">{label}</Label> : null}
          {onClose ? (<Close dataTestId="button-close-modal" onClick={onClose}/>) : null}
        </Header>
        <BodyWrapper className={className}>{children}</BodyWrapper>
      </InsideContainer>
    </OutsideContainer>, 
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.getElementById('modal-container-element'));
};
