import styles from './Header.module.css';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Burger } from './Burger';
import { BackArrow } from './BackArrow';
import { Arrow } from './Arrow';
import { observer } from 'mobx-react-lite';
import { useApplication } from '../../Contexts/ApplicationContext';
import { useNFT } from '../../Contexts/SettingsContext';
import { useAnalytics } from '../../Contexts/AnalyticsContext';
import { LanguageSelectorButton } from '../LanguageSelector';
export const Header = observer(forwardRef(function Header(props, ref) {
    var _a, _b, _c;
    const theme = useTheme();
    const { stepController: { goBack, currentStep, isCanGoBack }, } = useApplication();
    const { track } = useAnalytics();
    const smartContractData = useNFT();
    const { orderFacade: { exchangeInfo }, } = useApplication();
    const handleLanguageSelectorClick = useCallback(() => {
        track('language_selector_opened', {
            step: currentStep.step,
        });
        props.onLanguageSelectorClick();
    }, [currentStep.step, props, track]);
    const handleMenuClick = useCallback(() => {
        track('hamburger_menu_viewed', {
            step: currentStep.step,
        });
        props.onBurgerClick();
    }, [currentStep.step, props, track]);
    const chipInfo = useMemo(() => {
        var _a, _b, _c;
        const toInfoNft = smartContractData === null || smartContractData === void 0 ? void 0 : smartContractData.name;
        return (<>
          <span className={styles.pay_info_chip_span}>
            {exchangeInfo.payAmountString}
          </span>
          <Arrow />
          {toInfoNft ? (<span className={styles.received_info_chip_span}>{toInfoNft}</span>) : (<>
              <span className={styles.received_info_chip_span} style={{ maxWidth: 60 }}>
                {Number(exchangeInfo === null || exchangeInfo === void 0 ? void 0 : exchangeInfo.amounts.to.amount)}
              </span>
              {'\u00A0'}
              <span className={styles.received_info_chip_span} style={{ maxWidth: 50 }}>
                {(_c = (_b = (_a = exchangeInfo === null || exchangeInfo === void 0 ? void 0 : exchangeInfo.amounts) === null || _a === void 0 ? void 0 : _a.to) === null || _b === void 0 ? void 0 : _b.currency) === null || _c === void 0 ? void 0 : _c.code}
              </span>
            </>)}
        </>);
    }, [
        exchangeInfo === null || exchangeInfo === void 0 ? void 0 : exchangeInfo.amounts.to.amount,
        (_c = (_b = (_a = exchangeInfo === null || exchangeInfo === void 0 ? void 0 : exchangeInfo.amounts) === null || _a === void 0 ? void 0 : _a.to) === null || _b === void 0 ? void 0 : _b.currency) === null || _c === void 0 ? void 0 : _c.code,
        exchangeInfo.payAmountString,
        smartContractData === null || smartContractData === void 0 ? void 0 : smartContractData.name,
    ]);
    const handleBackClick = useCallback(() => {
        props.customBackClick ? props.customBackClick() : goBack();
    }, [goBack, props]);
    const showBackArrow = props.hideBackArrow
        ? false
        : props.customBackClick || isCanGoBack;
    return (<header className={styles.header_wrapper} ref={ref}>
        {props.label ? (<p className={styles.label}>{props.label}</p>) : (<>
            {showBackArrow ? (<BackArrow dataTestId="back-button" onClick={handleBackClick} fill={theme.colors.textBodyMain}/>) : (<div />)}
            {!props.hideChip && (<div className={styles.chip} data-test-id="header-chip">
                {chipInfo}
              </div>)}
          </>)}
        {props.headerChildren}
        <div className={styles.header_right_items}>
          {props.showLanguageSelectorButton && (<LanguageSelectorButton onClick={handleLanguageSelectorClick}/>)}
          <Burger onClick={handleMenuClick}/>
        </div>
      </header>);
}));
