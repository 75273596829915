import { action, makeObservable, observable } from 'mobx';
import { setUserToken } from '../userToken';
export var Page;
(function (Page) {
    Page["MAIN"] = "MAIN";
    Page["PHONE"] = "PHONE";
    Page["PHONE_OTP"] = "PHONE_OTP";
    Page["EMAIL_ADDRESS"] = "EMAIL_ADDRESS";
    Page["EMAIL_ADDRESS_OTP"] = "EMAIL_ADDRESS_OTP";
    Page["TARGET_ADDRESS"] = "TARGET_ADDRESS";
    Page["KYC"] = "KYC";
    Page["KYC_SIMPLE"] = "KYC_SIMPLE";
    Page["CHECK_ORDER"] = "CHECK_ORDER";
    Page["PAYMENT"] = "PAYMENT";
    Page["PAYMENT_PENDING"] = "PAYMENT_PENDING";
    Page["PAYMENT_FAILED"] = "PAYMENT_FAILED";
    Page["BLOCKED"] = "BLOCKED";
    Page["ORDER_PLACED"] = "ORDER_PLACED";
    Page["SUCCESS"] = "SUCCESS";
    Page["FAILED"] = "FAILED";
    Page["PAYMENT_3DS_REQUIRED"] = "PAYMENT_3DS_REQUIRED";
    Page["PAYMENT_WIRE_PENDING"] = "PAYMENT_WIRE_PENDING";
})(Page || (Page = {}));
export const MainStep = {
    step: Page.MAIN,
};
export const PhoneStep = {
    step: Page.PHONE,
};
function createInitialStepsHistory(currentStepsHistory, addMainStep = true) {
    if (!currentStepsHistory) {
        return [];
    }
    if (addMainStep) {
        return [MainStep, ...currentStepsHistory];
    }
    return currentStepsHistory;
}
export class StepController {
    constructor(startStep, stepsHistory, isOrderToken) {
        this.updateStepInfo = (info) => {
            const newStep = info.nextStep;
            const newStepHistory = info.stepsHistory;
            if ('userToken' in newStep && newStep.userToken) {
                setUserToken(newStep.userToken);
            }
            this.setStep(newStep);
            this.setStepsHistory(newStepHistory);
        };
        this.setStep = (step) => {
            this.isCurrentStepInitial = false;
            this.currentStep = step;
        };
        this.setStepsHistory = (stepsHistory) => {
            if (!stepsHistory) {
                // eslint-disable-next-line no-console
                console.warn('stepsHistory is not defined');
            }
            if (stepsHistory) {
                this.stepsHistory = this.isFirstStepAvailable
                    ? [MainStep, ...stepsHistory]
                    : [...stepsHistory];
            }
        };
        this.goBack = () => {
            if (this.stepsHistory.length > 0) {
                const newCurrentStep = this.stepsHistory[this.stepsHistory.length - 1];
                const newStepHistory = this.stepsHistory.slice(0, this.stepsHistory.length - 1);
                this.updateStepInfo({
                    nextStep: newCurrentStep,
                    stepsHistory: newStepHistory,
                });
            }
        };
        this.currentStep = startStep || MainStep;
        this.isFirstStepAvailable = !isOrderToken;
        this.stepsHistory = createInitialStepsHistory(stepsHistory, this.isFirstStepAvailable);
        this.isCurrentStepInitial = true;
        makeObservable(this, {
            currentStep: observable,
            updateStepInfo: action,
            stepsHistory: observable,
            isCurrentStepInitial: observable,
        });
    }
    get isCanGoBack() {
        return this.stepsHistory.length !== 0;
    }
}
