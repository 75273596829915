import React, { useCallback } from 'react';
import { ErrorIcon } from '../PaymentPage/icons';
import { InitializationErrorsCode } from '../../../Errors/InitializationErrors';
import { storage } from '../../../Services/storage';
import { AnimationPageLayout, PageLayout } from '../../Components/PageLayout';
import { CaptionLogo } from '../../Components/Footer/Caption/CaptionLogo';
import styles from './ErrorPage.module.css';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
export default function ErrorPage() {
    const reloadApp = useCallback(() => {
        storage.clearAll();
        window.location.reload();
    }, []);
    return (<PageLayout footer={{ actionButtonLabel: 'Try again', buttonClick: reloadApp }}>
      <div className={styles.icon_wrapper}>
        <ErrorIcon />
      </div>
      <div className={styles.title}>
        <Trans>Something went wrong</Trans>
      </div>
      <div className={styles.description}>
        <Trans>Try updating the widget</Trans>
      </div>
    </PageLayout>);
}
const InitializationErrorsText = {
    [InitializationErrorsCode.WrongApiKey]: {
        title: msg `Sorry, you use wrong API-KEY`,
        description: msg `You have to check your API-KEY on widget settings`,
    },
    [InitializationErrorsCode.NoOneCurrencyPair]: {
        title: msg `We cannot find any available currency pair`,
        description: msg `Check the widget settings, you may be trying to open a pair that doesn't exist.`,
    },
    [InitializationErrorsCode.UnknowingError]: {
        title: msg `Something went wrong`,
        description: msg `Try reloading the widget or contact support.`,
    },
    [InitializationErrorsCode.BlockedUser]: {
        title: msg `Transactions are limited`,
        description: msg `Unfortunately, we cannot process your transaction in accordance with the internal policy of the company.`,
    },
    [InitializationErrorsCode.BlockedGeo]: {
        title: msg `Coming soon to your region!`,
        description: msg `As soon as we are ready to work in your country, we will definitely let you know.`,
        img: './public/asset/errors/world.svg',
    },
};
export const ErrorOnLoadPage = ({ errorCode = InitializationErrorsCode.UnknowingError, }) => {
    const { _ } = useLingui();
    const { title, description, img } = InitializationErrorsText[errorCode];
    return (<AnimationPageLayout>
      <div className={styles.init_wrapper}>
        {img && <img className={styles.img} src={img}/>}
        <div className={styles.info}>
          <div className={styles.title}>{_(title)}</div>
          <div className={styles.description}>{_(description)}</div>
        </div>

        <a target="_blank" rel="noreferrer" href="https://swipelux.com" className={styles.caption}>
          <span>
            <Trans>Powered by</Trans>
          </span>
          <CaptionLogo />
        </a>
      </div>
    </AnimationPageLayout>);
};
